<template>
  <div class="home">
    <div class="logo">
      <img align="center" alt="" src="https://i.postimg.cc/FKKLpWqp/Handel-Bay-plataforma-digital-logo.png" width="300" style="max-width:787px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
    </div>
    <Countries />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Countries from './components/Countries.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Countries
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}
.logo{
  margin-bottom: 20px;
}
body{
  background-color: #E7E7E7;
}
</style>
