<template>
  <br><br>
  <div class="list-countries">
    <div v-for="country in countries" :key="country.id">
      <a :href="country.url" class="tooltip">
        <img :src="loadImage(country.image)" class="img">
        <span class="tooltiptext">{{country.name}}</span>
      </a>
      <div>
        {{country.name}}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  methods:{
    loadImage(name){
      return require('../assets/images/' + name)
    }
  },
  data(){
    return{
      countries:[
        {
          name: "Colombia",
          image: 'colombia.png',
          url: "https://handelbay.com.co"
        },
        {
          name: "Argentina",
          image: 'argentina.png',
          url: "https://handelbay.com.ar"
        }
      ]
    }
  }
}

</script>
<style >
.list-countries{
  display:flex;
  justify-content: center;
  margin-top: 20px;
}
.list-countries>div{
  margin: 0px 25px 0px 25px;
}
.img{
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin: 5px 15px;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>